import React, { useState } from 'react';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);


    const fetchData = async () => {
        console.log("Button clicked, fetching data...");  // Check if this logs in the console
        try {
            const response = await fetch("https://m0fyhe5pvf.execute-api.us-east-1.amazonaws.com/dev/allUsers");
            console.log("Response status:", response.status);  // Log the status code
            // Log the raw response to check if it's valid
            console.log("Raw response:", response);

            // Check if the response is OK
            if (!response.ok) {
                console.log("Failed to fetch data:", response.status);
                throw new Error(`Error: ${response.status}`);
            }


            const data = await response.json();
            if (response.ok) {
                console.log("Success: fetched data");
            } else {
                console.log("Failed: fetched data");
            }
            setUsers(data);
        } catch (error) {
            setError('Error fetching data');
            console.error("Fetch error:", error);  // Log the error details
        }
    };

    return (
        <div>
            <h1>Click to Get All Users</h1>
            <button onClick={fetchData}>Get User Data</button>

            {error && <p>{error}</p>}

            <div id="user-data">
                {users.length > 0 && (
                    <ul>
                        {users.map(user => (
                            <div key={user.id} className="user-box">
                                <li>ID: {user.id}</li>
                                <li>Name: {user.username}</li>
                                <li>Email: {user.email}</li>
                                <li>User Creation Date: {user.user_creation_date}</li>
                                <li>Is Above 18: {user.is_above_18}</li>
                            </div>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default UserList;
