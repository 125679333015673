import React from 'react';
import './App.css';  
import UserList from './UserList';  // Import the UserList component

function App() {
  return (
      <div className="App">
        <div className="company-name">Horizon LLC</div>

        <UserList />  {/* UserList  */}
      </div>
  );
}

export default App;
